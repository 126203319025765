import "./src/styles/global.css";
import "katex/dist/katex.css";

// https://github.com/gatsbyjs/gatsby/issues/38201#issuecomment-1631378720
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const currentPosition = getSavedScrollPosition(location);

  setTimeout(() => {
    window.scrollTo(...(currentPosition || [0, 0]));
  }, 0);

  return false;
};